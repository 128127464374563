<template>
  <div class="middle-box">
    <div class="login">
      <img
        src="https://cloud.primayer.com/Content/Images/Logos/PrimeWebOverWhite.png"
        class="logo-img"
      >
      <ol class="mt-3">
        <li v-if="primeweb">
          <b-input 
            ref="uInput"
            v-model="username"
            type="text"
            :placeholder="strings.username"
            @keyup.enter="login"
          />
        </li>
        <li
          v-if="primeweb"
          class="mt-3"
        >
          <b-input 
            v-model="password"
            type="password"
            :placeholder="strings.password"
            @keyup.enter="login"
          />
        </li>
        <li v-if="!primeweb">
          <b-input 
            ref="SerialInput"
            v-model="serial"
            type="text"
            :placeholder="strings.serial"
            @keyup.enter="login"
          />
        </li>
        <li
          v-if="!primeweb"
          class="mt-3"
        >
          <b-input 
            v-model="accessCode"
            type="password"
            :placeholder="strings.accessCode"
            @keyup.enter="login"
          />
        </li>
        <li class="mt-3">
          <b-button
            variant="ovarro-yellow"
            @click="login"
          >
            {{ strings.login }}
          </b-button>
          <font-awesome-icon
            v-if="loggingIn"
            class="ml-1"
            icon="spinner"
            pulse
          />
        </li>
        <li
          v-if="invalid"
          class="mt-3"
        >
          {{ strings.invalidLogin }}
        </li>
      </ol>
    </div>
    <div class="password">
      <b-button-group>
        <b-button
          variant="link"
          @click="toggleReset"
        >
          <span v-if="!reset">{{ strings.forgotPassword }}?</span>
          <span v-if="reset">{{ strings.close }}</span>
        </b-button>
        <b-button
          variant="link"
          @click="toggleLogin"
        >
          <span v-if="primeweb">Xilog</span>
          <span v-if="!primeweb">PrimeWeb</span>
        </b-button>
        <b-button
          v-if="primeweb"
          variant="link"
          @click="showEureka"
        >
          Eureka
        </b-button>
      </b-button-group>
    </div>
    <div
      v-if="reset"
      class="reset"
    >
      <div v-if="!isSent">
        <p class="text-warning">
          {{ strings.forgotPasswordMessage }}
        </p>
        <ol>
          <li class="form-group">
            <b-input 
              v-model="username"
              type="text"
              :placeholder="strings.username"
            />
          </li>
          <li>
            <b-button
              variant="ovarro-yellow"
              @click="sendReset"
            >
              {{ strings.send }}
            </b-button>
          </li>
        </ol>
      </div>
      <div v-if="isSent">
        <p class="text-success">
          {{ strings.forgotPasswordSentMessage }}
        </p>
        <b-button
          variant="success"
          @click="toggleIsSent"
        >
          {{ strings.forgotPasswordTryAgain }}
        </b-button>
      </div>
    </div>
    <eureka-modal />
  </div>
</template>

<script>
import EurekaModal from "./EurekaUnlock";
export default {
  components: {
    EurekaModal
  },
  data() {
    return {
      username: "",
      password: "",
      serial: "",
      accessCode: "",
      reset: false,
      isSent: false,
      loggingIn: false,
      invalid: false,
      primeweb: true
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.uInput.focus();
    }, 500);
  },
  methods: {
    login() {
      if(this.primeweb) {
        this.primeWebLogin();
      }
      else {
        this.oneFmLogin();
      }
    },
    primeWebLogin() {
      if(!this.loggingIn && this.username != "" && this.password != "") {
        this.loggingIn = true;
        this.invalid = false;
        var creds = {
          username: this.username,
          password: this.password
        }
        this.$store.dispatch("login", creds)
        .then(res => {
          if(!res.success) {
            this.loggingIn = false;
            this.invalid = true;
          }
          else {
            window.location = res.returnUrl;
          }
        })
      }
    },
    oneFmLogin() {
      if(!this.loggingIn && this.serial != "" && this.accessCode != "") {
        this.loggingIn = true;
        this.invalid = false;
        var creds = {
          username: this.serial,
          password: this.accessCode
        }
        this.$store.dispatch("oneFmLogin", creds)
        .then(res => {
          if(!res.success) {
            this.loggingIn = false;
            this.invalid = true;
          }
          else {
            window.location = res.returnUrl;
          }
        })
      }
    },
    toggleReset() {
      this.reset = !this.reset;
    },
    toggleLogin() {
      this.primeweb = !this.primeweb;
    },
    showEureka() {
      this.$bvModal.show("eureka-modal")
    },
    sendReset() {
      if(this.username != "") {
        this.isSent = true;
        var obj = {
          username: this.username,
        }
        this.$store.dispatch("sendReset", obj);
      }
    },
    toggleIsSent() {
      this.username = "";
      this.isSent = false;
    }
  }
}
</script>

<style scoped>

</style>