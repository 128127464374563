<template>
  <div id="app">
    <main-view />
  </div>
</template>

<script>
import View from "./views/Main";
export default {
  components: {
    "main-view": View
  }
}
</script>

<style lang="scss">

@import "assets/theme.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
#app {
  height: 100%;
  background: theme-color("ovarro-black");
}
.logo-img {
  width:250px;
}
.middle-box {
  text-align: center;
  width: 300px;
  margin: 100px auto 20px auto;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: padding-box;
  box-sizing: content-box;
}
.middle-box .login {
  padding: 30px 20px 5px 20px;
  background: #eee;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.middle-box .login h1 {
  margin-top: 0;
}

.middle-box .login ol {
  list-style: none;
  padding-left: 0;
}

.middle-box .login .login-form ol {
  padding: 0;
  margin: 0;
}

.middle-box .login .login-form .checkbox {
  display: inline;
}

.middle-box .password {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #eee;
  background: #eee;
  padding: 5px 0 10px 0;
  margin-bottom: 10px;
}

.middle-box .reset {
  font-weight: 100;
}

.middle-box .reset ol {
  list-style: none;
  padding-top: 10px;
  padding-left: 0;
}

.btn.btn-ovarro-yellow {
  color: theme-color("ovarro-black");
  background-color: theme-color("ovarro-yellow1");
  border-color: theme-color("ovarro-yellow1");
   display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
.btn.btn-ovarro-yellow:hover {
  color: theme-color("ovarro-yellow1");
  background-color: theme-color("ovarro-black");
  border-color: theme-color("ovarro-black");
}

</style>
