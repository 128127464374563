<template>
  <div class="home">
    <div
      fluid
      class="d-flex flex-column"
    >
      <div class="alert alert-info cookie-msg">
        {{ strings.cookieMessage }}
      </div>
       <div class="alert alert-info cookie-msg" v-if="ieUser">
        {{ strings.noIE }} 
        <strong><a href="https://www.google.com/chrome" class="text-warning underline">Google Chrome</a></strong>/
        <strong><a href="https://www.mozilla.org/en-GB/firefox/new/" class="text-warning underline">FireFox</a></strong>/
        <strong><a href="https://www.microsoft.com/en-us/edge" class="text-warning underline">New Edge</a></strong>
      </div>
      <div v-if="isReset">
        <reset-entry />
      </div>
      <div v-if="!isReset">
        <login-entry />
      </div>
    </div>
  </div>
</template>

<script>
import Login from "../components/Login";
import Reset from "../components/Reset";
export default {
  components: {
    "login-entry": Login,
    "reset-entry": Reset
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    isReset() {
      return this.$store.getters.reset;
    },
    ieUser() {
      if (window.document.documentMode) {
        return true;
      }
      else {
        return false;
      }
    }
  },
  mounted() {
    let mainElement = document.getElementById("core-data");
    var apiUrl = mainElement.dataset.apiUrl;
    var returnUrl = mainElement.dataset.returnUrl;
    var reset = mainElement.dataset.reset == "True";
    var resetUser = mainElement.dataset.resetUser;
    var resetDate = mainElement.dataset.resetDate;
    this.$store.commit("setApiUrl", apiUrl);
    this.$store.commit("setReset", reset);
    this.$store.commit("setResetUser", resetUser);
    this.$store.commit("setResetDate", resetDate);
    this.$store.commit("setReturnUrl", returnUrl);
    this.$store.dispatch("getStrings");
  }
}
</script>

<style scoped>
.home {
  height: 100vh;
}
.cookie-msg {
  text-align: center;
}
</style>