<template>
  <div class="middle-box">
    <div class="login">
      <img
        src="https://cloud.primayer.com/Content/Images/Logos/PrimeWebOverWhite.png"
        class="logo-img"
      >
      <ol class="mt-3">
        <li>
          <b-input
            ref="pwInput"
            v-model="password"
            type="password"
            :placeholder="strings.newPassword"
            autocomplete="off"
            :readonly="loading"
            :state="newPassValidation"
            :title="strings.passwordTitleMessage"
          />
        </li>
        <li class="mt-3">
          <b-input
            v-model="confirm"
            type="password"
            :placeholder="strings.confirmPassword"
            autocomplete="off"
            :readonly="loading"
            :state="passwordsMatch"
          />
        </li>
        <li class="mt-3">
          <b-button
            variant="ovarro-yellow"
            @click="reset"
          >
            {{ strings.reset }}
          </b-button>
          <font-awesome-icon
            v-if="resetting"
            class="ml-1"
            icon="spinner"
            pulse
          />
        </li>
        <li
          v-if="errorMessage !== ''"
          class="mt-3"
        >
          {{ errorMessage }}
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      confirm: "",
      resetting: false,
      loading: true,
      errorMessage: ""
    }
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    newPassValidation() {
      let testText = this.password;
      let hasNumber = /[0-9]+/;
      let hasUpper = /[A-Z]+/;
      let hasLower = /[a-z]+/;
      let hasMinMaxChar = /^.{8,15}$/;
      let hasSymbols = /[!@#$%^&*()_+=[{\]};:<>|./?,-]/;

      if (!hasNumber.test(testText)) {
        return false;
      } else if (!hasUpper.test(testText)) {
        return false;
      } else if (!hasLower.test(testText)) {
        return false;
      } else if (!hasMinMaxChar.test(testText)) {
        return false;
      } else if (!hasSymbols.test(testText)) {
        return false;
      } else {
        return true;
      }
    },
    passwordsMatch() {
      return this.newPassValidation && this.password === this.confirm;
    }
  },
  mounted() {
    setTimeout(() => {
        this.loading = false;
        this.$refs.pwInput.focus();
      }, 500);
  },
  methods: {
    reset() {
      if(!this.resetting && this.newPassValidation && this.passwordsMatch) {
        this.resetting = true;
        var creds = {
          password: this.password,
          confirmPassword: this.confirm
        }
        this.$store.dispatch("reset", creds)
        .then(res => {
          this.resetting = false;
          if(res.errorMessage && res.errorMessage !== "") {
              this.errorMessage = res.errorMessage;
          }
          else {
            window.location = "../../login";
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>