import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);

library.add(
  faSpinner);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;

export const EventBus = new Vue();

new Vue({
  store,
  render: h => h(App)
}).$mount("#app");
