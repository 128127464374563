import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import { Products, Types, ActivityFirestore } from "@primayer/activity-firestore";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    reset: false,
    loading: false,
    strings: {},
    apiUrl: "https://cloud.primayer.com",
    returnUrl: "",
    resetUser: "",
    resetDate: "",
    config: {
      apiKey: "AIzaSyBza8fijYK0D6eDBkES2w6Z0AwW7Sqy7aI",
      authDomain: "primeactivity.firebaseapp.com",
      databaseURL: "https://primeactivity.firebaseio.com",
      projectId: "primeactivity",
      storageBucket: "primeactivity.appspot.com",
      messagingSenderId: "563607438651",
      appId: "1:563607438651:web:fb11b1635f5e0a4586631e",
      measurementId: "G-FPR41841CH"
    }
  },
  getters: {
    strings(state) {
      return state.strings;
    },
    loading(state) {
      return state.loading
    },
    apiUrl(state) {
      return state.apiUrl;
    },
    reset(state) {
      return state.reset;
    },
    returnUrl(state) {
      return state.returnUrl;
    },
    resetUser(state) {
      return state.resetUser;
    },
    resetDate(state) {
      return state.resetDate;
    }
  },
  mutations: {
    setStrings(state, payload) {
      state.strings = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setApiUrl(state, payload) {
      state.apiUrl = payload;
    },
    setReset(state, payload) {
      state.reset = payload;
    },
    setReturnUrl(state, payload) {
      state.returnUrl = payload;
    },
    setResetUser(state, payload) {
      state.resetUser = payload;
    },
    setResetDate(state, payload) {
      state.resetDate = payload;
    }
  },
  actions: {
    initFirestore({ state }) {
      ActivityFirestore.init(state.config);
    },
    getStrings({ commit, state, dispatch }) {
      const url = `${state.apiUrl}/api/login/getStrings`;
      axios
        .get(url)
        .then(res => {
          commit("setStrings", res.data);
          dispatch("initFirestore");
        })
        .catch(error => {
          console.warn(error);
        });
    },
    login({ state, dispatch }, creds) {
      return new Promise(resolve => {
        creds.returnUrl = state.returnUrl;
        const options = {
          method: "POST",
          headers: { "content-type": "application/json; charset=utf-8" },
          data: creds,
          url: `${state.apiUrl}/api/login/login`
        };
        axios(options)
          .then(res => {
            if(res.data.success) {
              dispatch("createActivity", {
                product: Products.user,
                type: Types.login,
                data: res.data
              });
            }
            resolve(res.data);
          })
          .catch(error => {
            console.warn(error);
          });
      })
    },
    oneFmLogin({ state }, creds) {
      return new Promise(resolve => {
        creds.returnUrl = "";
        const options = {
          method: "POST",
          headers: { "content-type": "application/json; charset=utf-8" },
          data: creds,
          url: `${state.apiUrl}/api/onefm/login`
        };
        axios(options)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            console.warn(error);
          });
      })
    },
    sendReset({ state }, obj) {
      const options = {
        method: "POST",
        headers: { "content-type": "application/json; charset=utf-8" },
        data: obj,
        url: `${state.apiUrl}/api/login/sendreset`
      };
      axios(options)
        .then(res => {
          console.log(res.data);
        })
        .catch(error => {
          console.warn(error);
        });
    },
    reset({ state, dispatch }, creds) {
      creds.username = state.resetUser;
      creds.Date = state.resetDate;
      return new Promise(resolve => {
        const options = {
          method: "POST",
          headers: { "content-type": "application/json; charset=utf-8" },
          data: creds,
          url: `${state.apiUrl}/api/login/reset`
        };
        axios(options)
          .then(res => {
            if(res.data.errorMessage == "") {
              dispatch("createActivity", {
                product: Products.user,
                type: Types.resetPassword,
                data: res.data
              });
            }
            resolve(res.data);
          })
          .catch(error => {
            console.warn(error);
          });
      })
    },
    eurekaUnlock({ state }, obj) {
      return new Promise(resolve => {
        const options = {
          method: "POST",
          headers: { "content-type": "application/json; charset=utf-8" },
          data: obj,
          url: `${state.apiUrl}/api/admineureka/unlock`
        };
        axios(options)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            console.warn(error);
          });
      })
    },
    createActivity({ getters }, obj) {
      ActivityFirestore.add(obj.data.id, {
        id: obj.data.id,
        username: obj.data.username,
        timestamp: new Date(),
        product: obj.product,
        activityObj: obj.data,
        actionType: obj.type
      });
    }
  },
  modules: {}
});
