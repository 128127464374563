<template>
  <b-modal
    id="eureka-modal"
    title="Eureka Unlock"
    header-bg-variant="ovarro-black"
    header-text-variant="light"
    body-bg-variant="ovarro-black"
    body-text-variant="light"
    footer-bg-variant="ovarro-black"
    footer-text-variant="light"
    ok-title="unlock"
    ok-variant="ovarro-yellow"
    ok-only
    no-close-on-backdrop
    @show="reset"
    @ok="unlock"
  >
    <div>
      <b-row>
        <label for="nameInput">{{ strings.name }}</label>
        <b-input
          id="nameInput"
          v-model="name"
        />
      </b-row>
      <b-row>
        <label for="orderInput">{{ strings.orderNumber }}</label>
        <b-input
          id="orderInput"
          v-model="order"
        />
      </b-row>
      <b-row>
        <label for="codeInput">{{ strings.unlockCode }}</label>
        <b-input
          id="codeInput"
          v-model="code"
        />
      </b-row>
      <b-row v-if="showResponse">
        <label for="responseInput"> Result </label>
        <b-input
          id="responseInput"
          v-model="response"
          disabled
        />
      </b-row>
      <b-row>
        <font-awesome-icon
          v-if="unlocking"
          icon="spinner"
          pulse
        />
      </b-row>
    </div>
  </b-modal>  
</template>

<script>
export default {
  data() {
    return {
      name: "",
      order: "",
      code: "",
      response: "",
      unlocking: false
    }
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    showResponse() {
      return this.response !== "" && !this.unlocking;
    }
  },
  methods: {
    reset() {
      this.name = "";
      this.order = "";
      this.code = "";
      this.response = "";
      this.unlocking = false;
    },
    unlock(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.unlocking = true;
      var obj = {
        name: this.name,
        orderNumber: this.order,
        code: this.code
      };

      this.$store.dispatch("eurekaUnlock", obj)
      .then(res => {
        this.response = res;
        this.unlocking = false;
      })
    }
  }
}
</script>

<style scoped>
.row {
  padding: 1em;
}
</style>